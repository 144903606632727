/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

//Plugin maneiro
(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
          var obj = this, args = arguments;
          function delayed () {
              if (!execAsap) {
                  func.apply(obj, args);
              }
              timeout = null;
          }
          if (timeout) {
              clearTimeout(timeout);
          } else if (execAsap) {
              func.apply(obj, args);
          }
          timeout = setTimeout(delayed, threshold || 100);
      };
  };
  // smartresize
  jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //ANIMAÇÃO NAVBAR
          $(".navbar-toggle").on("click", function () {
            $(this).toggleClass("active");
          });
        // Menu mobile
          function ajuste_menu() {
            if($(window).width() < 768) {
              $('.navbar-nav.menu-principal>li>ul>li.dropdown>a').each(function() {
                $(this).attr('data-toggle', 'dropdown');
                $(this).addClass('dropdown-toggle');
                $(this).attr('aria-haspopup', 'true');
              });
              $('.navbar-nav.menu-principal').on('hide.bs.dropdown', function () {
                $('.navbar-nav.menu-principal .menu-item').each(function() {
                  if(!$(this).hasClass('open')) {
                    $(this).removeClass('hidden');
                  }
                });
                $('.menu-item.open').find('.menu-item').removeClass( "hidden" );
                $('.navbar-nav.menu-principal').find('.sub-menu').removeClass( "ativado" );
              });
              $('.navbar-nav.menu-principal').on('shown.bs.dropdown', function () {
                $('.navbar-nav.menu-principal .menu-item').each(function() {
                  if(!$(this).hasClass('open') && $(window).width() < 768) {
                    $(this).addClass('hidden');
                  }
                  else if ($(this).hasClass('open')) {
                    if($(this).parent('ul.sub-menu').hasClass('dropdown-menu')) {
                      $(this).parent('ul.sub-menu').addClass('ativado');
                      $(this).parent('ul.sub-menu').parent('li').removeClass('hidden');
                      // console.log($(this).parent('ul.sub-menu').parent('li').children('a').addClass('hidden'));
                    }
                  }
                });
                $('.menu-item.open').find('.menu-item').removeClass( "hidden" );
              });
            } else {
              $('.navbar-nav.menu-principal').find('.menu-item').removeClass( "hidden" );
            }
          }
          ajuste_menu();
          $(window).smartresize(function(){
            ajuste_menu();
          });
        //Correções modal
          function centerModal() {
            $(this).css('display', 'block');
            var $dialog  = $(this).find(".modal-dialog"),
            offset       = ($(window).height() - $dialog.height()) / 2,
            bottomMargin = parseInt($dialog.css('marginBottom'), 10);
            // Make sure you don't hide the top part of the modal w/ a negative margin if it's longer than the screen height, and keep the margin equal to the bottom margin of the modal
            if(offset < bottomMargin) {
              offset = bottomMargin;
            }
            $dialog.css("margin-top", offset);
          }
          $(document).on('show.bs.modal', '.modal', centerModal);
          $(window).on("resize", function () {
              $('.modal:visible').each(centerModal);
          });
          $('.modal').bind('hidden.bs.modal', function () {
            $("html").css("margin-right", "0px");
          });
          $('.modal').bind('show.bs.modal', function () {
            $("html").css("margin-right", "-15px");
          });
        //ANCORA - SOBE DO RODAPÉ
          $('a.button-sobe').click(function(event){
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $( $(this).attr('href') ).offset().top
            }, 500);
            return false;
          });
        //AJUSTES DE TABELA RESPONSIVAS
            $('table').addClass('table');
            $('table').wrap('<div class="table-responsive"></div>');

        // Bandeiras
          // $('ul.idiomas li a').each(function() {
          //   $(this).children('img').attr('src', '/kantariboperepucom/dist/images/polylang/' + $(this).attr('hreflang') + '.svg');
          //   // $(this).children('img').attr('width', 'auto');
          // });
          $('ul.idiomas li a').fadeIn();
        // ICONES BOX DUPLO
          $(".contem-box-interno").click(function(){
            $(".icones-box-duplo-interno").fadeOut("fast");
            $(this).find(".icones-box-duplo-interno").addClass("icone-ativo");
            $(".icone-ativo").css({"display":"block"});
            $(this).find(".icones-box-duplo-interno").removeClass("icone-ativo");
          });
          $(".fecha-box-interno").click(function() {
            $(".icones-box-duplo-interno").fadeOut("low");
          });
        //CRIA JUMBO MENU
          $(".menu-principal>li.menu-item>ul.sub-menu").each(function() {
            //Adicionar classe
              var $classe = $(this).parent().attr('class').replace("dropdown-menu", "").replace(" dropdown", "").replace("menu-item ", "").replace("active ", "");
              $(this).after('<div class="arrow-up ' + $classe + '"></div>');
              var $divcon = $("<div>", {'class' : 'sub-menu-arrasta ' + $classe});
              $divcon.appendTo('#menu-modificado');
              var $duplicado = $(this)
                .clone()
                .appendTo('.sub-menu-arrasta.' + $classe)
                .removeClass('dropdown-menu')
                .addClass($classe + " row");
              if(!$(this).find('ul.sub-menu').length) {
                $duplicado.children('.menu-item').addClass('col-sm-6');
                $divcon.addClass('col-sm-10 col-md-6 jumbo ' + $classe );
                $divcon.before('<h1 class="col-sm-6 jumbo hidden-sm ' + $classe + '">'+$(this).prev().html());
                $divcon.after('<div class="col-xs-12 jumbo">');
              } else {
                $duplicado.children('.menu-item').addClass('perfil col-sm-' + 12/$(this).find('ul.sub-menu').length );
                $divcon.addClass('col-sm-12 col-md-10 jumbo ' + $classe );
                $divcon.before('<h1 class="col-md-2 jumbo hidden-sm ' + $classe + '">'+$(this).prev().html());
                $divcon.after('<div class="col-xs-12 jumbo">');
              }
          });
          $('#menu-modificado > div.sub-menu-arrasta > ul.sub-menu.row > li.menu-item.dropdown > a').on('click', function(e) {
            e.preventDefault();
          });
          $('.menu-principal>.menu-item.menu-busca').append('<div class="arrow-up menu-busca"></div>');
          $('#menu-modificado').find('.dropdown-menu').removeClass("dropdown-menu").addClass("coluna-menu");
        //Ocultar jumboboxs
          $('#menu-modificado .jumbo, .arrow-up').css({ 'display' : 'none' });
        //CONTROLA JUMBO BOX ABRE
          $('.dropdown').on('show.bs.dropdown', function () {
            var $classe = $(this).attr('class').replace("active", "").replace("menu-item ", "").replace("dropdown", "");
            var width = $( window ).width(), height = $( window ).height(); //Largura do browser
            if(width >= 768) {
              $('.arrow-up.' + $classe).fadeIn( 300 );
              $('.jumbo.' + $classe).slideToggle( 300 );
            }
          });
        //CONTROLA JUMBO BOX FECHA
          $('.dropdown').on('hide.bs.dropdown', function () {
            var $classe = $(this).attr('class').replace("active ", "").replace("dropdown", "").replace(" open", "");
            var width = $( window ).width(), height = $( window ).height(); //Largura do browser
            if(width >= 768) {
              $('.arrow-up').delay(300).fadeOut( 100 );
              $('.jumbo').not('.jumbo.' + $classe).slideUp( 300 );
              $('.jumbo.' + $classe).slideToggle( 300 );
            }
          });
        //EVITA FECHAR COMBO BOX
          $('.search-field, .search-submit').click(function(event){
            event.stopPropagation();
          });
        //Animação home
        $('.solucao-bannerlinks:hover .sblinks-txt').on('mouseover', function() {
          $(this).velocity({  'color' : '#8cc63e',  'margin-top': '-30px' });
        });
        //FORMULÁRIOS
          var tam = $(window).width();
          if (tam >= 768 ){
            $(function(){
              $('.formulario-contato input').each(function(){
                var $this = $(this),$offset = $this.offset();
                var $placeholder = $("<p>")
                .text( $this.attr("placeholder") )
                .addClass("contato-placeholder");
                $placeholder.insertBefore($this).show(200);
                var width_placeholder = $this.prev('.contato-placeholder').width();
                $this.css({'padding-left':width_placeholder+36});
              });
            });
            $(function(){
              $('.formulario-contato textarea').each(function(){
                var $this = $(this),$offset = $this.offset();
                var $placeholder = $("<p>")
                .text( $this.attr("placeholder") )
                .addClass("textearea-placeholder");
                $placeholder.insertBefore($this).show(200);
                var width_placeholder = $this.prev('.textearea-placeholder').width();
                $this.css({'padding-left':width_placeholder+42});
              });
            });

          } else {
            $(function(){
              $('.formulario-contato input').each(function(){
                var $this = $(this), $offset = $this.offset();
                var $placeholder = $("<p>")
                .text( $this.attr("placeholder") )
                .addClass("contato-placeholder");
                $placeholder.insertBefore($this).show(200);
              });
            });
            $(function(){
              $('.formulario-contato textarea').each(function(){
                var $this = $(this), $offset = $this.offset();
                var $placeholder = $("<p>")
                  .text( $this.attr("placeholder") )
                  .addClass("textearea-placeholder");
                $placeholder.insertBefore($this).show(200);
              });
            });
          }

          // HOME -> FILTRAR POR
          $( ".filtrar-esportes" ).click(function(){
            $( ".barra-filtro" ).animate({ "height": "15px" }, 100, function() {
              $( "#subfiltro-esportes" ).animate({ "height": "165px" }, 250 );
            });
          });

          $( ".filtrar-esportes" ).click(function(){
            $(".filtrar-por ul li a").removeClass( "ativado" );
            $(this).addClass( "ativado" );
          });

          $( ".filtrar-entreternimento" ).click(function(){
            $( "#subfiltro-esportes" ).animate({ "height": "0px" }, 200, function() {
              $( ".barra-filtro" ).animate({ "height": "0px" }, 50, function() {
                $(".filtrar-por ul li a").removeClass( "ativado" );
                $(this).addClass( "ativado" );
              });
            });
          });

          $( ".filtrar-tudo" ).click(function(){
            $( "#subfiltro-esportes" ).animate({ "height": "0px" }, 200, function() {
              $( ".barra-filtro" ).animate({ "height": "0px" }, 50, function() {
                $(".filtrar-por ul li a").removeClass( "ativado" );
                $(this).addClass( "ativado" );
              });
            });
          });

          $( ".fechar-subfiltro" ).click(function(){
            $( "#subfiltro-esportes" ).animate({ "height": "0px" }, 200, function() {
              $( ".barra-filtro" ).animate({ "height": "0px" }, 50, function() {
                $(".filtrar-por ul li a").removeClass( "ativado" );
              });
            });
          });

          $( "#clickme" ).click(function() {
            $( "#book" ).fadeIn( "slow", function() {
            });
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Estudos
    'post_type_archive_estudos': {
      init: function() {
        // JavaScript to be fired on the home page
          $container = $('#estudos-isotope');
          $container.imagesLoaded(function(){
            $container.isotope({
              itemSelector : '.item'
            });
          });
          $('#estudos-filtro a').on('click', function(e) {
              e.preventDefault();
              $container.isotope({ filter: $(this).attr('href').replace('#', '.') });
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //ISOTOPE
          function isotope_carousel() {
            var $container = $('#isotope');
            if($(window).width() < 768) {
              // console.log('meteoro');
              $container.isotope('destroy');
              $('#noticias-destaque').addClass('carousel slide');
              $('#noticias-destaque>#isotope').addClass('carousel-inner');
              $('#noticias-destaque').hammer().bind("swipeleft", function(ev) {
                $('#noticias-destaque').carousel('next');
              });
              $('#noticias-destaque').hammer().bind("swiperight", function(ev) {
                $('#noticias-destaque').carousel('prev');
              });
              $('#noticias-destaque .item').removeClass('hidden');
            } else {
              $('#noticias-destaque').removeClass('carousel slide');
              $('#noticias-destaque>#isotope').removeClass('carousel-inner');
              $container.imagesLoaded(function(){
                $container.isotope({
                  // options
                  itemSelector : '.item',
                  // isFitWidth: true,
                  transitionDuration: '0.5s',
                  layoutMode : 'masonry',
                  masonry: {
                    columnWidth: '.col-sm-4',
                    gutter: 0
                  }
                });
              });
            }
          }
          isotope_carousel();
          $(window).smartresize(function(){
            isotope_carousel();
          });
          $container = $('#isotope');
          $('#home-filtro a').on('click', function(e) {
              e.preventDefault();
              if($(this).hasClass('active')) {
                // console.log('tem');
                return;
              }
              // console.log('meteoro');
              $('#isotope .item').each(function() {
                $(this).removeClass('hidden');
              });
              $('#home-filtro a').each(function() {
                $(this).removeClass('active');
              });
              $(this).addClass('active');
              $container.isotope({
                filter: $(this).attr('href').replace('#', '.')
              });
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'post_type_archive_noticias': {
      init: function() {
        // JavaScript to be fired on the about us page
        //ISOTOPE
          function isotope_carousel() {
            var $container = $('#isotope');
            if($(window).width() < 768) {
              // console.log('meteoro');
              $container.isotope('destroy');
              $('#noticias-destaque').addClass('carousel slide');
              $('#noticias-destaque>#isotope').addClass('carousel-inner');
              $('#noticias-destaque').hammer().bind("swipeleft", function(ev) {
                $('#noticias-destaque').carousel('next');
              });
              $('#noticias-destaque').hammer().bind("swiperight", function(ev) {
                $('#noticias-destaque').carousel('prev');
              });
              $('#noticias-destaque .item').removeClass('hidden');
            } else {
              $('#noticias-destaque').removeClass('carousel slide');
              $('#noticias-destaque>#isotope').removeClass('carousel-inner');
              $container.imagesLoaded(function(){
                $container.isotope({
                  // options
                  itemSelector : '.item',
                  // isFitWidth: true,
                  transitionDuration: '0.5s',
                  layoutMode : 'masonry',
                  masonry: {
                    columnWidth: '.col-md-3',
                    gutter: 0
                  }
                });
              });
            }
          }
          isotope_carousel();
          $(window).smartresize(function(){
            isotope_carousel();
          });
          $container = $('#isotope');
          $('#home-filtro a').on('click', function(e) {
              e.preventDefault();
              if($(this).hasClass('active')) {
                // console.log('tem');
                return;
              }
              // console.log('meteoro');
              $('#isotope .item').each(function() {
                $(this).removeClass('hidden');
              });
              $('#home-filtro a').each(function() {
                $(this).removeClass('active');
              });
              $(this).addClass('active');
              $container.isotope({
                filter: $(this).attr('href').replace('#', '.')
              });
          });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
